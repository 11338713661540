import { render, staticRenderFns } from "./content.vue?vue&type=template&id=0496c12a&"
import script from "./content.vue?vue&type=script&lang=js&"
export * from "./content.vue?vue&type=script&lang=js&"
import style0 from "./content.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GovBanner: require('/var/www/cicada-idnr/nuxt/components/GovBanner/GovBanner.vue').default,Search: require('/var/www/cicada-idnr/nuxt/components/Search/Search.vue').default,SocialButton: require('/var/www/cicada-idnr/nuxt/components/SocialButton/SocialButton.vue').default})
