//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Search from "~/components/Search/Search";
import GetPageAndChildren from "~/apollo/queries/getPageAndChildren";
import GetMainMenu from "~/apollo/queries/getMainMenu";
import GetSecondaryMenu from "~/apollo/queries/getSecondaryMenu";
import GetFooterText from "~/apollo/queries/getFooterText";
import SocialButton from "~/components/SocialButton/SocialButton.vue";
import GovBanner from "~/components/GovBanner/GovBanner.vue";

export default {
  components: {
    Search,
    SocialButton,
    GovBanner,
  },
  data() {
    return {
      showMenu: false,
      activePage: "activeTab",
      socialList: [
        {
          label: "Email",
          url: "mailto: cicada-idnr@gmail.com?subject=Message from CICADA",
        },
        {
          label: "Facebook",
          url: "https://www.facebook.com/IllinoisDNR/",
        },
        {
          label: "Twitter",
          url: "https://twitter.com/IllinoisDNR",
        },
        {
          label: "Instagram",
          url: "https://instagram.com/illinoisdnr?igshid=1kg6i3idj9cax",
        },
      ],
      isDropdownOpen_residential: false,
      isDropdownOpen_commercial: false,
      pageResidential: [],
      pageCommercial: [],
    };
  },
  apollo: {
    pageResidential: {
      query: GetPageAndChildren,
      variables() {
        return { id: "/residential" };
      },
      update(data) {
        return data.page?.children?.edges?.map((e) => e.node);
      },
    },
    pageCommercial: {
      query: GetPageAndChildren,
      variables() {
        return { id: "/commercial" };
      },
      update(data) {
        return data.page?.children?.edges?.map((e) => e.node);
      },
    },
    postBy: {
      query: GetFooterText,
      update(data) {
        // console.log("GetFooterText data",data);
        return data.postBy;
      },
    },
    mainMenu: {
      query: GetMainMenu,
      update(data) {
        return data.menu?.menuItems?.edges?.map((e) => e.node);
      },
    },
    secondaryMenu: {
      query: GetSecondaryMenu,
      update(data) {
        return data.menu?.menuItems?.edges?.map((e) => e.node);
      },
    },
  },
  computed: {
    footerText() {
      return this.pageBy?.content;
    },
  },
  watch: {
    async "pageResidential"(newValue) {
      for (const children of newValue) {
        await this.fetchAndStoreChildren(children);
      }
    },

    async "pageCommercial"(newValue) {
      for (const children of newValue) {
        await this.fetchAndStoreChildren(children);
      }
    },
  },
  mounted() {
    // Fetch children data once component is mounted (optional)
    this.pageResidential.forEach(children => {
      this.fetchAndStoreChildren(children);
    });

    this.pageCommercial.forEach(children => {
      this.fetchAndStoreChildren(children);
    });
  },
  methods: {
    showNav() {
      // console.log("Ping");
      this.showMenu = this.showMenu === false;
    },
    getClass(x) {
      const activeClass = this.activePage;
      if (this.$route.fullPath.includes(x.toLowerCase())) {
        return activeClass;
      }
    },
    select(itemId) {
      this.selectedId = itemId;
      this.displayCategory = !this.displayCategory;
    },
    toggleDropdown(page, boolVal) {
      if (page === "residential") {
        this.isDropdownOpen_residential = boolVal;
      } else if (page === "commercial") {
        this.isDropdownOpen_commercial = boolVal;
      }
    },
    async fetchChildren(uri) {
      try {
        const result = await this.$apollo.query({
          query: GetPageAndChildren, // The Apollo query for fetching children data
          variables: { id: uri }, // Passing the URI as a variable
        });
        return result.data.page?.children?.edges?.map(e => e.node) || [];
      } catch (error) {
        console.error("Error fetching child data:", error);
        return [];
      }
    },
    async fetchAndStoreChildren(children) {
      if (!children.uri) return;
      children.childData = await this.fetchChildren(children.uri);
    },
    handleClickSelf(event, uri) {
      if (event.target === event.currentTarget) {
        this.handleClick(uri);
      }
    },
    handleClick(uri) {
      this.$router.push(uri);
      this.isDropdownOpen_residential = false;
      this.isDropdownOpen_commercial = false;
    },
    closeDropdown() {
      this.isDropdownOpen_residential = false;
      this.isDropdownOpen_commercial = false;
    }
  },
};
